<template>
  <div>
      <van-cell-group>
          <van-cell title="个人资料"  is-link :to="{path:'/user/edit/userInfo',query:{id:userInfo.id}}"/>  
      </van-cell-group>

  </div>
</template>
<script>
import {
  Cell,
  CellGroup,
  Button,
} from "vant";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button
  },
  data() {
    return {
            userInfo:{}
    };
  },
  mounted: function() {
     this.userInfo = this.$store.state.userInfo
     //获取传递过来的id
     if(this.$route.query.id){
         
     }
  },
  methods: {
  
    toNav(){

    }

  }
};
</script>
<style lang="less">

.save{
  width: 90%;
  margin: 0 auto;
  margin-top: 60px;
}
</style>